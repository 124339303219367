import React from 'react';
import { Nav, Navbar, Row, Image } from 'react-bootstrap';
import { compose } from 'recompose';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faClipboardCheck, faCalendar, faCog } from '@fortawesome/free-solid-svg-icons';

import { AuthUserContext, withAuthorization } from '../../session';
import LogOutButton from '../logout';
import Header from '../../components/Header';
import { NavLink } from 'react-router-dom';
import { ROUTES, SOCIAL_MEDIA_IMAGE } from '../../util';

const DashboardPage = () => {
  return (
    <AuthUserContext.Consumer>
      {authUser => (
        <div>
            <div className="gs-dashboard-nav mt-auto mb-auto bg-light-blue p-0 d-none d-md-block">
                <Header />
                <Nav variant="pills" className="flex-column pt-5">
                  <NavLink className="nav-link" activeClassName="active" to={ROUTES.DASHBOARD_HOME}><FontAwesomeIcon icon={faHome}/> Home</NavLink>
                  <NavLink className="nav-link" activeClassName="active" to={ROUTES.DASHBOARD_APPLICATIONS}><FontAwesomeIcon icon={faClipboardCheck}/> Applications</NavLink>
                  <NavLink className="nav-link" activeClassName="active" to={ROUTES.DASHBOARD_ACCOUNT}><FontAwesomeIcon icon={faCog}/> Account</NavLink>
                </Nav>

                <div className="gs-logout-button">
                    <LogOutButton />
                </div>
            </div>
            <div className="gs-dashboard-nav-mobile d-md-none">
                <Navbar collapseOnSelect expand="lg">
                    <Row className="gs-navbar-logo">
                        <Image src={SOCIAL_MEDIA_IMAGE.logo_short} className="pr-2 pl-3 pt-4 pb-4 mt-auto mb-auto" fluid />
                        <h4 className="text-black mt-auto mb-auto"><b>Global</b> Spark</h4>
                    </Row>
                    <Navbar.Toggle />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                          <NavLink className="nav-link" activeClassName="active" to={ROUTES.DASHBOARD_HOME}>Home</NavLink>
                          <NavLink className="nav-link" activeClassName="active" to={ROUTES.DASHBOARD_APPLICATIONS}>Applications</NavLink>
                          <NavLink className="nav-link" activeClassName="active" to={ROUTES.DASHBOARD_ACCOUNT}>Account</NavLink>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        </div>
      )}
    </AuthUserContext.Consumer>
  )
};

const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
)(DashboardPage);
