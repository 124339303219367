import React from 'react';
import { withRouter, Route, Redirect } from 'react-router-dom';

import LoginPage from '../pages/login';
import ForgetPasswordPage from '../pages/forgetpassword';
import ForgetPasswordConfirmPage from '../pages/forgetpassword/forgetpasswordconfirm';
import RegisterPage from '../pages/register';
import NonAdminPage from '../pages/nonadmin';
import DashboardPage from '../pages/dashboard';
import HomePage from '../pages/dashboard/home';
import ApplicationsPage from '../pages/dashboard/applications';
import ApplicationPage from '../pages/dashboard/application';
import SchedulePage from '../pages/dashboard/schedule';
import AccountPage from '../pages/dashboard/account';

import { ROUTES } from '../util';
import { AuthUserContext } from '../session';


const RouterItems = () => (
    <AuthUserContext.Consumer>
        { authUser => (authUser && authUser.acc_type === 'admin') ? (<RouterItemsAuthed authUser={authUser} />) : (<RouterItemsNonAuthed />) }
    </AuthUserContext.Consumer>
);

const RouterItemsAuthed = () => {
    return (
        <div>
            <Route path={ROUTES.HOME} exact><Redirect to={ROUTES.DASHBOARD_APPLICATIONS} /></Route>
            <Route path={ROUTES.LOGIN}><Redirect to={ROUTES.DASHBOARD_HOME} /></Route>
            <Route path={ROUTES.NON_ADMIN}><Redirect to={ROUTES.DASHBOARD_HOME} /></Route>
            <Route path={ROUTES.DASHBOARD} component={DashboardPage} />
            <Route path={ROUTES.DASHBOARD_HOME} component={HomePage} />
            <Route path={ROUTES.DASHBOARD_APPLICATIONS} exact component={ApplicationsPage} />
            <Route path={ROUTES.DASHBOARD_ACCOUNT} component={AccountPage} />
            <Route path={ROUTES.DASHBOARD_APPLICATION} exact component={ApplicationPage} />
            <Route path={ROUTES.DASHBOARD_SCHEDULE} exact component={SchedulePage} />
        </div>
    );
};

const RouterItemsNonAuthed = () => {
    return (
        <div>
            <Route path={ROUTES.HOME}><Redirect to={ROUTES.LOGIN} /></Route>
            <Route path={ROUTES.DASHBOARD}><Redirect to={ROUTES.NON_ADMIN} /></Route>
            <Route path={ROUTES.NON_ADMIN} component={NonAdminPage} />
            <Route path={ROUTES.LOGIN} component={LoginPage} />
            <Route path={ROUTES.REGISTER} component={RegisterPage} />
            <Route path={ROUTES.FORGET_PASSWORD} component={ForgetPasswordPage} />
            <Route path={ROUTES.FORGET_PASSWORD_CONFIRM} component={ForgetPasswordConfirmPage} />
        </div>
    );
};

export default withRouter(RouterItems);
