import React, { Component } from 'react';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';

import { PAGES_IMAGE, ROUTES } from '../../util';
import { withFirebase } from '../../firebase';
import Header from '../../components/Header';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const ERROR_MSG_ACCOUNT_EXISTS = `An account with this E-Mail address already exists.`;

const RegisterPage = () => (
    <div>
        <Container fluid>
            <Row className="h-100 mt-auto mb-auto">
                <Col md={5} xl={4}>
                    <Header />
                    <div className="gs-form-container pr-5 pl-5">
                        <h3 className="pb-3">Sign Up for Admin Portal</h3>
                        Already Have An Account? <Link to={ROUTES.LOGIN}>Log In</Link>
                        <RegisterForm />
                    </div>
                </Col>
                <Col md={7} xl={8} className="bg-light-yellow h-100 p-0 d-none d-md-block">
                    <Image src={PAGES_IMAGE.login} className="gs-login-image" />
                </Col>
            </Row>
        </Container>
    </div>
);

const INITIAL_STATE = {
    firstname: '',
    lastname: '',
    email: '',
    passwordOne: '',
    passwordTwo: '',
    error: null,
    disableButton: false,
};

class RegisterFormBase extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        event.preventDefault();
        this.setState({ disableButton: true });

        const { firstname, lastname, email, passwordOne } = this.state;

        this.props.firebase.doRegisterWithEmailAndPassword(email, passwordOne).then(authUser => {
            const userData = {
                first_name: firstname,
                last_name: lastname,
                email: email,
                acc_type: "hacker",
                acceptance_status: "no_app",
                rsvp_option: false,
                typeform_response_id: "",
            }
            return this.props.firebase.doCreateUserRecord(authUser.user.uid, userData);
        }).then(() => {
            this.setState({ ...INITIAL_STATE });
            this.props.history.push(ROUTES.DASHBOARD_HOME);
        }).catch(error => {
            if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
                error.message = ERROR_MSG_ACCOUNT_EXISTS;
            }
            
            this.setState({ error });
            this.setState({ disableButton: false });
        });
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onChangeCheckbox = event => {
      this.setState({ [event.target.name]: event.target.checked });
    };

    render() {
        const {firstname, lastname, email, passwordOne, passwordTwo, error,} = this.state;
        const isInvalid = passwordOne !== passwordTwo || passwordOne === '' || email === '' || firstname === '' || lastname === '';

        return (
            <Form onSubmit={this.onSubmit}>
                <div className="pt-5 pb-5">
                <Form.Group>
                    <Form.Control name="firstname" value={firstname} onChange={this.onChange} type="text" placeholder="First Name" />
                </Form.Group>
                <Form.Group>
                    <Form.Control name="lastname" value={lastname} onChange={this.onChange} type="text" placeholder="Last Name" />
                </Form.Group>
                <Form.Group>
                    <Form.Control name="email" value={email} onChange={this.onChange} type="email" placeholder="Email" />
                </Form.Group>
                <Form.Group>
                    <Form.Control name="passwordOne" value={passwordOne} onChange={this.onChange} type="password" placeholder="Password" />
                </Form.Group>
                <Form.Group>
                    <Form.Control name="passwordTwo" value={passwordTwo} onChange={this.onChange} type="password" placeholder="Confirm Password" />
                </Form.Group>
                <Form.Group>
                    You will require additional authorization to access admin portal.
                </Form.Group>
                </div>
                <Button className="mb-3" disabled={isInvalid || this.state.disableButton} type="submit">
                    Let's Go <FontAwesomeIcon icon={faArrowRight}/>
                </Button>
                <div className="pt-3">
                    {error && <p>{error.message}</p>}
                </div>
            </Form>
        );
    }
}

const RegisterForm = withRouter(withFirebase(RegisterFormBase));

export default RegisterPage;
