import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { compose } from 'recompose';

import { withFirebase } from '../../../firebase';
import { AuthUserContext, withAuthorization } from '../../../session';

const HomePage = () => {
    return (
        <div className="gs-dashboard-content">            
            <Container className="gs-home-container">
                <Home />
            </Container>
        </div>
    )
};

const INITIAL_STATE = {
  accepted: 0,
  rejected: 0,
  undecided: 0,
  waitlisted: 0
}

class HomeBase extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    componentDidMount() {
      this.props.firebase.applications().once('value').then(snapshot => {
        var reviewCounts = {
          accepted: 0,
          rejected: 0,
          undecided: 0,
          waitlisted: 0
        };
        const data = snapshot.val();
        for (const app in data) {
          reviewCounts[data[app].acceptanceStatus] += 1;
        }
        this.setState(reviewCounts);
      });
    }

    render() {
        return (
          <AuthUserContext.Consumer>
            {authUser => (
              <div>
                <h3 className="pb-5 text-center">Hey, {authUser.first_name}!</h3>
                <h5 className="pb-3">Application Review Progress: </h5>
                <Row className="pb-3">
                  <Col xs={4}>
                    <h5 className="text-success text-center">{this.state.accepted} Accepted</h5>
                  </Col>
                  <Col xs={4}>
                    <h5 className="text-warning text-center">{this.state.waitlisted} Waitlisted</h5>
                  </Col>
                  <Col xs={4}>
                    <h5 className="text-danger text-center">{this.state.rejected} Rejected</h5>
                  </Col>
                </Row>
                <h4 className="pb-3">
                  {this.state.undecided+this.state.accepted+this.state.waitlisted+this.state.rejected} Total Applications
                </h4>
                <h4 className="pb-3">{this.state.undecided} Not Yet Reviewed</h4>
              </div>
            )}
          </AuthUserContext.Consumer>
        );
    }
}

const condition = authUser => !!authUser;

const Home = compose(
    withFirebase,
    withAuthorization(condition),
)(HomeBase);

export default HomePage
