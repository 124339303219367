import React, { Component } from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import { withFirebase } from '../../../firebase';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction";


const SchedulePage = () => (
    <div className="gs-dashboard-content">
        <Schedule />
    </div>
);

const INITIAL_STATE = {
    events: [],
    show: false,
    clicked: {title:"temp title", extendedProps:{}},
};

class ScheduleBase extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
        this.day = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    }

    componentDidMount() {        
        this.props.firebase.events().once('value').then(snapshot => {
            var eventsData = [];
            const data = snapshot.val();
            for (const event in data) {
                eventsData.push(data[event]);
            }
            this.setState({events: eventsData});
        });
    }
  
    handleClose = () => this.setState({show: false});
    handleShow = () => this.setState({show: true});

    handleEventClick = ({event, el}) => {
        this.setState({clicked: event})
        this.handleShow();
    }

    renderEventContent = (eventInfo) => {
        return (
            <div className="gs-event-block">
                <h5>{eventInfo.timeText}</h5>
                <p>{eventInfo.event.title}</p>
            </div>
        )
    }

    renderDayHeaderContent = (dayInfo) => {
        return (
            <div className="pb-4">
                <h5>{dayInfo.date.getDate()}</h5>
                <h5>{this.day[dayInfo.date.getDay()]}</h5>
            </div>
        )
    }

    render() {
        return (
            <Container className="gs-schedule-container">
                <Modal centered show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header>
                        <Modal.Title>
                            {this.state.clicked.title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{this.state.clicked.extendedProps.desc}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleClose}>Close</Button>
                    </Modal.Footer>
                </Modal>

                <FullCalendar
                    plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin ]}
                    headerToolbar={{
                        left: 'dayGrid dayGrid3Day',
                        center: 'prev today next',
                        right: ''
                    }}
                    buttonText={{
                        today: 'Today'
                    }}
                    dayHeaderFormat={{
                        weekday:'long',
                        day: 'numeric',
                        omitCommas: true
                    }}
                    views={{
                        dayGrid: {
                            type: 'dayGrid',
                            buttonText: 'Day'
                        },
                        dayGrid3Day: {
                            type: 'dayGrid',
                            duration: { days: 3 },
                            buttonText: '3 Day'
                        }
                    }}
                    contentHeight="auto"
                    initialView='dayGrid3Day'
                    eventClick={this.handleEventClick}
                    eventContent={this.renderEventContent}
                    dayHeaderContent={this.renderDayHeaderContent}
                    events={this.state.events}
                />
            </Container>
        )
    }
}

const Schedule = withFirebase(ScheduleBase);

export default SchedulePage;
