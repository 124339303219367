import React, { useState, useEffect } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { Row, Button, Card, Form, Modal } from 'react-bootstrap';
import { compose } from 'recompose';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEdit, faSync } from '@fortawesome/free-solid-svg-icons';

import { withFirebase } from '../../../firebase';
import { ROUTES } from '../../../util';

const ApplicationPage = (props) => {
  const { firebase } = props;
  const [app, setApp] = useState({});
  const [review, setReview] = useState({comments: ""});
  const [commentsEdit, setCommentsEdit] = useState("");
  const [statusEdit, setStatusEdit] = useState("undecided");
  const [disableRefresh, setDisableRefresh] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const statusColourMap = {
    "accepted": "text-success",
    "rejected": "text-danger",
    "undecided": "text-warning",
    "waitlisted": "text-warning"
  }
  const statusTextMap = {
    "accepted": "Accepted",
    "rejected": "Rejected",
    "undecided": "Undecided",
    "waitlisted": "Waitlisted"
  }

  const exists = e => {return e != null && e !== "";}
  const boolToStr = bool => {return bool ? "Yes":"No";}
  const dateStrToStr = date => {
    if (exists(date) && date.length >= 10) {
      return date.substring(0, 10);
    }
  };
  const reviewEditHandler = event => {
    setCommentsEdit(review.comments);
    setStatusEdit(app.acceptanceStatus);
    setShowCommentModal(true);
    event.preventDefault();
  };
  const handleCommentSave = () => {
    firebase.review(id).set({comments: commentsEdit}).then(() => {
      firebase.application(id).update({acceptanceStatus: statusEdit}).then(() => {
        getApp();
        getReview();
        setShowCommentModal(false);
      })
    }).catch(error => {
      setError(error);
    });
  }
  const onBackClick = () => {
    props.history.push(`${ROUTES.DASHBOARD_APPLICATIONS}`);
  }

  const getApp = () => {
    return firebase.application(id).once('value').then(snapshot => {
      console.log("current app:", snapshot.val());
      setApp(snapshot.val());
    });
  };

  const getReview = () => {
    setDisableRefresh(true);
    return firebase.review(id).once('value').then(snapshot => {
      console.log("current review:", snapshot.val());
      setReview(snapshot.val());
      setTimeout(() => setDisableRefresh(false), 2000);
    });
  };

  useEffect(() => {
    getApp();
    getReview();
  }, [setApp, setReview]);

  return (
    <div className="gs-dashboard-content">
          {
            app != null &&
            (
            <div className="gs-application-container">
              <Row>
                <Button variant="link" className="pt-0 pb-0" onClick={onBackClick} >
                  <FontAwesomeIcon icon={faArrowLeft}/>
                </Button>
                <h2><b>Applicant:</b> {app.firstName} {app.lastName}</h2>
              </Row>

              {showCommentModal}
              <Card className="mt-4 mb-4">
                <Card.Header>
                  <span className="float-right">
                    <Button variant="link" className="pt-1 pb-0" disabled={disableRefresh} onClick={()=>{getApp(); getReview();}} >
                      <FontAwesomeIcon icon={faSync}/>
                    </Button>
                    <Button variant="link" className="pt-1 pb-0 pr-0" onClick={reviewEditHandler}>
                      <FontAwesomeIcon icon={faEdit}/>
                    </Button>
                  </span>
                  Application Review
                </Card.Header>
                <Card.Body>
                  <p><b>Current Application Status:</b> <span className={statusColourMap[app.acceptanceStatus]}>{statusTextMap[app.acceptanceStatus]}</span></p>
                  {review.comments ? (
                  <p>
                    <b>Comments</b>
                    <br />
                    {review.comments}
                  </p>
                  ) : "No Comments"}
                </Card.Body>
              </Card>

              <Card className="mb-4">
                <Card.Header>
                  Personal Information
                </Card.Header>
                <Card.Body>
                  <p><b>First Name:</b> {app.firstName}</p>
                  <p><b>Last Name:</b> {app.lastName}</p>
                  <p><b>Email:</b> {app.email}</p>
                  <p><b>University:</b> {app.university}</p>
                  <p><b>Major of Study:</b> {app.majorOfStudy}</p>
                  <p><b>Year of Study:</b> {app.yearOfStudy}</p>
                  <p><b>Date of Graduation:</b> {dateStrToStr(app.graduationDate)}</p>
                  <p><b>Country:</b> {app.country}</p>
                  <p><b>Phone Number:</b> {app.phoneNum}</p>
                  <p><b>Gender:</b> {app.gender}</p>
                  <p><b>Will be 18 by Feb 27th:</b> {boolToStr(app.kickoffAfter18)}</p>
                </Card.Body>
              </Card>

              <Card className="mb-4">
                <Card.Header>
                  Team Information
                </Card.Header>
                <Card.Body>
                  {
                    app.team === "Yes" ? (
                      <>
                        <p><b>Teammate 1:</b> {app.teammate1}</p>
                        <p><b>Teammate 2:</b> {app.teammate2}</p>
                        <p><b>Teammate 3:</b> {app.teammate3}</p>                        
                        <p><b>Willing to regroup team:</b> {boolToStr(app.wouldRegroup)}</p>
                      </>
                    ) : "Applicant does not have a team"
                  }
                </Card.Body>
              </Card>

              <Card className="mb-4">
                <Card.Header>
                  Short Answers
                </Card.Header>
                <Card.Body>
                  <p>
                    <b>What's an example of a time that you took initiative or demonstrated leadership in your community? </b>
                    <br />
                    {app.demonstrateLeadership}
                  </p>
                  <p>
                    <b>Tell us about an emerging technology you are passionate about and how it can be newly applied to support a global/social issue. </b>
                    <br />
                    {app.passionateTechnology}
                  </p>
                  <p>
                    <b>Tell us your craziest startup idea.</b>
                    <br />
                    {app.startupIdea}
                  </p>
                  <p><b>Given permission to share resume with corporate partners: </b>{boolToStr(app.shareResumePermission)}</p>
                  <p><b><a href={app.resumeLink} target="_blank" rel="noopener noreferrer">Resume</a></b></p>
                  {exists(app.linkedinLink) && <p><b><a href={app.linkedinLink} target="_blank" rel="noopener noreferrer">Linkedin</a></b></p>}
                  {exists(app.otherLinks) && <p><b><a href={app.otherLinks} target="_blank" rel="noopener noreferrer">Other Links</a></b></p>}
                </Card.Body>
              </Card>

              <Card>
                <Card.Header>
                  Preferences and Notes
                </Card.Header>
                <Card.Body>
                  <p><b>Hackathon Expertise:</b> {app.hackathonExpertise}</p>
                  <p><b>Theme Interests:</b> {app.interestedTheme}</p>
                  <p><b>Workshop Interests:</b> {app.workshopsInterest}</p>
                  {exists(app.finalNotes) !== "" && 
                    <p>
                      <b>Final notes</b>
                      <br />
                      {app.finalNotes}
                    </p>
                  }
                </Card.Body>
              </Card>
            
              <Modal centered show={showCommentModal} onHide={()=>{setShowCommentModal(false)}}>
                  <Modal.Header closeButton>
                      <Modal.Title>
                          {app.firstName} {app.lastName} Application Review
                      </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                      <Form>
                        <Form.Group>
                          <Form.Label>Comments</Form.Label>
                          <Form.Control as="textarea" value={commentsEdit} onChange={e=>{setCommentsEdit(e.target.value)}} rows={5} />
                        </Form.Group>

                        <Form.Group>
                          <Form.Label>Acceptance Status</Form.Label>
                          <Form.Control as="select" value={statusEdit} onChange={e=>{setStatusEdit(e.target.value)}}>
                            <option>accepted</option>
                            <option>undecided</option>
                            <option>waitlisted</option>
                            <option>rejected</option>
                          </Form.Control>
                        </Form.Group>
                      </Form>
                      {error && <p className="text-danger">{error.message}</p>}
                  </Modal.Body>
                  <Modal.Footer>
                      <Button onClick={handleCommentSave}>Save</Button>
                  </Modal.Footer>
              </Modal>
            </div>
            )
          }
    </div>
  )
};

export default compose(withRouter, withFirebase)(ApplicationPage);
