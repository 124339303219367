import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';

import { withFirebase } from '../../firebase';
import { ROUTES, PAGES_IMAGE } from '../../util';
import Header from '../../components/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const LoginPage = () => (
    <div>
        <Container fluid>
            <Row className="h-100 mt-auto mb-auto">
                <Col md={5} xl={4}>
                    <Header />
                    <div className="gs-form-container pr-5 pl-5">
                        <h3 className="pb-3">Login to Admin Portal</h3>
                        Don't Have An Account? <Link to={ROUTES.REGISTER}>Sign Up</Link>
                        <LoginForm />
                    </div>
                </Col>
                <Col md={7} xl={8} className="bg-light-yellow h-100 p-0 d-none d-md-block">
                    <Image src={PAGES_IMAGE.login} className="gs-login-image" />
                </Col>
            </Row>
        </Container>
    </div>
);

const INITIAL_STATE = {
    email: '',
    password: '',
    error: null,
    disableButton: false,
};

class LoginFormBase extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        event.preventDefault();
        this.setState({ disableButton: true });
        const { email, password } = this.state;

        this.props.firebase.doLoginWithEmailAndPassword(email, password).then(() => {
            this.setState({ ...INITIAL_STATE });
            this.props.history.push(ROUTES.DASHBOARD_HOME);
        }).catch(error => {
            this.setState({ disableButton: false });
            this.setState({ error });
        });
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { email, password, error } = this.state;

        const isInvalid = password === '' || email === '';

        return (
            <Form onSubmit={this.onSubmit}>
                <div className="pt-5">
                    <Form.Group>
                        <Form.Control name="email" value={email} onChange={this.onChange} type="email" placeholder="Email" />
                    </Form.Group>
                    <Form.Group controlId="loginForm.password">
                        <Form.Control name="password" value={password} onChange={this.onChange} type="password" placeholder="Password" />
                    </Form.Group>
                </div>
                <div className="pb-5">
                    <Link to={ROUTES.FORGET_PASSWORD}>Forget Password</Link>
                </div>
                <Button className="mb-3" disabled={isInvalid || this.state.disableButton} type="submit">
                    Let's Go <FontAwesomeIcon icon={faArrowRight}/>
                </Button>
                {error && <p>{error.message}</p>}
            </Form>
        );
    }
}

const LoginForm = compose(
    withRouter,
    withFirebase,
)(LoginFormBase);

export default LoginPage;
