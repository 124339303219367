import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import MaterialTable from 'material-table';

import { withFirebase } from '../../../firebase';
import { ROUTES } from '../../../util';

const ApplicationsPage = (props) => {
  const { firebase } = props;
  const [apps, setApps] = useState([]);

  const getApps = () => {
    console.log("get apps");
    firebase.applications().once('value').then(snapshot => {
      var appsData = [];
      const data = snapshot.val();
      for (const appData in data) {
        var tempData = {};
        tempData['teammate1'] = data[appData]['teammate1'];
        tempData['teammate2'] = data[appData]['teammate2'];
        tempData['teammate3'] = data[appData]['teammate3'];
        tempData['acceptanceStatus'] = data[appData]['acceptanceStatus'];
        tempData['response_id'] = appData;
        tempData['name'] = `${data[appData]['firstName']} ${data[appData]['lastName']}`;
        appsData.push(tempData);
      }
      console.log("Getting Apps");
      setApps(appsData);
    });
  };

  const handleAppClick = (rowData) => {
    props.history.push(`${ROUTES.DASHBOARD_APPLICATIONS}/${rowData.response_id}`);
  }

  useEffect(() => {
    if (apps.length <= 0) {
      getApps();
    }
  });

  return (
    <div className="gs-dashboard-content">
      <div className="gs-applications-container w-100">
        <a href='/assets/applications.csv' download>Download CSV</a>
        {
          apps && 
          <MaterialTable
            columns={[
              { title: 'Name', field: 'name'},
              { title: 'Status', field: 'acceptanceStatus'},
              { title: 'Teammate 1', field: 'teammate1'},
              { title: 'Teammate 2', field: 'teammate2'},
              { title: 'Teammate 3', field: 'teammate3'},
            ]}
            options={{
              pageSize: 20,
              pageSizeOptions: [20,50,100,500],
            }}
            title="Applications"
            onRowClick={(event, rowData, togglePanel) => handleAppClick(rowData)}
            data={apps}
          />
        }
      </div>
    </div>
  )
};

export default compose(withRouter, withFirebase)(ApplicationsPage);
