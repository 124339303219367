import React from 'react';
import { BrowserRouter as Router} from 'react-router-dom';

import Footer from './components/Footer';
import RouterItems from './components/RouterItems';
import { withAuthentication } from './session';

const App = () => {
    return (
        <Router>
            <div className="gs-content">
                <RouterItems />
                <Footer />
            </div>
        </Router>
    );
};

export default withAuthentication(App);
