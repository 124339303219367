import React from 'react';
import { Button } from 'react-bootstrap';

import { withFirebase } from '../../firebase';

const LogoutButton = ({ firebase }) => (
    <Button className="gs-text gs-link" onClick={firebase.doLogout}>
        Log out
    </Button>
);

export default withFirebase(LogoutButton);
