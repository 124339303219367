export const ROUTES = {
    HOME: '/',
    LOGIN: '/login',
    REGISTER: '/register',
    FORGET_PASSWORD: '/forgetpassword',
    FORGET_PASSWORD_CONFIRM: '/forgetpasswordconfirm',
    EMAIL_VERIFICATION: '/emailverification',
    NON_ADMIN: '/NON_ADMIN',
    DASHBOARD: '/dashboard',
    DASHBOARD_HOME: '/dashboard/home',
    DASHBOARD_APPLICATIONS: '/dashboard/applications',
    DASHBOARD_APPLICATION: '/dashboard/applications/:id',
    DASHBOARD_SCHEDULE: '/dashboard/schedule',
    DASHBOARD_EVENT: '/dashboard/schedule/:id',
    DASHBOARD_ACCOUNT: '/dashboard/account',
};

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const TYPEFORM_URL = "https://contactus182245.typeform.com/to/O0tSPbx3";

export const TYPEFORM_ID = "O0tSPbx3";

export const USERS_TABLE_NAME = "users";
export const APPLICATIONS_TABLE_NAME = "application";
export const REVIEWS_TABLE_NAME = "review";
export const EVENTS_TABLE_NAME = "events";

export const EXTERNAL_LINKS = {
    FACEBOOK: 'https://www.facebook.com/globalspark.world',
    INSTAGRAM: 'https://www.instagram.com/global.spark',
    LINKEDIN: 'https://www.linkedin.com/company/global-spark-world/',
    TWITTER: 'https://twitter.com/geweek?lang=en',
    EMAIL: 'contactus@globalspark.world',
};

export const SOCIAL_MEDIA_IMAGE = {
    facebook_logo_black: require('../assets/images/socialMediaIcons/facebook_black.svg'),
    instagram_logo_black: require('../assets/images/socialMediaIcons/instagram_black.svg'),
    linkedin_logo_black: require('../assets/images/socialMediaIcons/linkedin_black.svg'),
    twitter_logo_black: require('../assets/images/socialMediaIcons/twitter_black.svg'),
    facebook_logo_white: require('../assets/images/socialMediaIcons/facebook_white.svg'),
    instagram_logo_white: require('../assets/images/socialMediaIcons/instagram_white.svg'),
    linkedin_logo_white: require('../assets/images/socialMediaIcons/linkedin_white.svg'),
    twitter_logo_white: require('../assets/images/socialMediaIcons/twitter_white.svg'),
    logo: require('../assets/images/logo/bright-bg-long.png'),
    logo_short: require('../assets/images/logo/bright-bg-short.png'),
    footer_graphics: require('../assets/images/home/footer-graphics.svg'),
};

export const PAGES_IMAGE = {
    login: require('../assets/images/pages/login.svg'),
    password: require('../assets/images/pages/password.svg'),
};
