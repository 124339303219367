import React, { Component } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../../../firebase';
import { ROUTES } from '../../../util';

const AccountPage = () => (
    <div className="gs-dashboard-content">
        <Account />
    </div>
);

const INITIAL_STATE = {
    firstname: '',
    lastname: '',
    passwordOne: '',
    passwordTwo: '',
    nameUpdated: false,
    passwordUpdated: false,
    error: null,
};

class AccountBase extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    resetForm() {
        this.props.firebase.user(this.props.firebase.curUser().uid).once('value').then((snapshot) => {
            const data = snapshot.val();
            this.setState({firstname: data.first_name, lastname: data.last_name})
        });
    };

    componentDidMount() {
        this.resetForm();
    };
    
    handleDiscard = event => {
        this.props.history.push(ROUTES.DASHBOARD_HOME);
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onSubmitName = event => {        
        const { firstname, lastname } = this.state;
        this.setState({ error: null });
        
        this.props.firebase.doUpdateName(firstname, lastname).then(() => {
            this.setState({nameUpdated: true});
            this.resetForm();
        }).catch(error => {
            this.setState({ error });
        });
    
        event.preventDefault();
    };

    onSubmitPassword = event => {
        const { passwordOne, passwordTwo } = this.state;
        this.setState({ error: null });
        
        this.props.firebase.doLoginWithEmailAndPassword(this.props.firebase.curUser().email, passwordOne).then(() => {
            this.props.firebase.doPasswordUpdate(passwordTwo).then(() => {
                this.setState({ ...INITIAL_STATE });
                this.setState({passwordUpdated: true});
                this.resetForm();
            }).catch(error => {
                this.setState({ error });
            });
        }).catch(error => {
            this.setState({ error });
        });
    
        event.preventDefault();
    };

    render() {
        const {firstname, lastname, passwordOne, passwordTwo, error, nameUpdated, passwordUpdated} = this.state;
        const isInvalidName = firstname === '' || lastname === '';
        const isInvalidPassword = passwordOne === '';

        return (
            <Container className="gs-account-container">
                <h3>Account Settings</h3>
                <Form>
                    <div className="pt-5 pb-2">
                        <Form.Group as={Row}>
                            <Form.Label column sm="5">First Name</Form.Label>
                            <Col sm="7">
                                <Form.Control name="firstname" value={firstname} onChange={this.onChange} type="text" placeholder="First Name" />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="5">Last Name</Form.Label>
                            <Col sm="7">
                                <Form.Control name="lastname" value={lastname} onChange={this.onChange} type="text" placeholder="Last Name" />
                            </Col>
                        </Form.Group>
                    </div>
                    { nameUpdated ? <>Success!</> : <Button className="mb-3 mr-3" disabled={isInvalidName} onClick={this.onSubmitName}>Save</Button> }
                </Form>
                <Form>
                    <div className="pt-3 pb-2">
                        <Form.Group as={Row}>
                            <Form.Label column sm="5">Old Password</Form.Label>
                            <Col sm="7">
                                <Form.Control name="passwordOne" value={passwordOne} onChange={this.onChange} type="password" placeholder="Old Password" />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="5">New Password</Form.Label>
                            <Col sm="7">
                                <Form.Control name="passwordTwo" value={passwordTwo} onChange={this.onChange} type="password" placeholder="New Password" />
                            </Col>
                        </Form.Group>
                    </div>
                    { passwordUpdated ? <>Success!</> : <Button className="mb-3 mr-3" disabled={isInvalidPassword} onClick={this.onSubmitPassword}>Save</Button> }
                    <div className="pt-3">
                        {error && <p>{error.message}</p>}
                    </div>
                </Form>
            </Container>
        );
    }
}

const Account = compose(
    withRouter,
    withFirebase,
)(AccountBase);

export default AccountPage;
