import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import { BASE_URL, ROUTES, USERS_TABLE_NAME, APPLICATIONS_TABLE_NAME, REVIEWS_TABLE_NAME, EVENTS_TABLE_NAME } from '../util';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
};

class Firebase {
    constructor() {
        app.initializeApp(firebaseConfig);

        /* Helper */
        this.serverValue = app.database.ServerValue;
        this.emailAuthProvider = app.auth.EmailAuthProvider;

        /* Firebase APIs */
        this.auth = app.auth();
        this.db = app.database();
    }

    doRegisterWithEmailAndPassword = (email, password) => this.auth.createUserWithEmailAndPassword(email, password);

    doLoginWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);

    doLogout = () => this.auth.signOut();

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    doSendEmailVerification = () => this.auth.currentUser.sendEmailVerification({url: BASE_URL + ROUTES.EMAIL_VERIFICATION});

    doCreateUserRecord = (uid, userData) => this.user(uid).set(userData);

    doUpdateTypeformResponseId = (response_id) => {
        return this.user(this.auth.currentUser.uid).update({
            typeform_response_id: response_id,
            acceptance_status: "submitted",
        });
    }

    doUpdateRSVPOption = (option) => this.user(this.auth.currentUser.uid).update({rsvp_option: option,});

    doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

    doUpdateName = (firstname, lastname) => this.user(this.auth.currentUser.uid).update({first_name: firstname, last_name: lastname});

    curUser = () => this.auth.currentUser;

    // *** Merge Auth and DB User API *** //

    onAuthUserListener = (next, fallback) => this.auth.onAuthStateChanged(authUser => {
        if (authUser) {
            this.user(authUser.uid).once('value').then(snapshot => {
                const dbUser = snapshot.val();

                // merge auth and db user
                authUser = {
                    uid: authUser.uid,
                    email: authUser.email,
                    emailVerified: authUser.emailVerified,
                    providerData: authUser.providerData,
                    ...dbUser,
                };

                next(authUser);
            });
        } else {
            fallback();
        }
    });

    user = uid => this.db.ref(`${USERS_TABLE_NAME}/${uid}`);
    application = id => this.db.ref(`${APPLICATIONS_TABLE_NAME}/${id}`);
    review = id => this.db.ref(`${REVIEWS_TABLE_NAME}/${id}`);
    applications = () => this.db.ref(`${APPLICATIONS_TABLE_NAME}`);
    reviews = () => this.db.ref(`${REVIEWS_TABLE_NAME}`);
    events = () => this.db.ref(`${EVENTS_TABLE_NAME}`);
}

export default Firebase;